import { Box } from '@mui/material';
import React from 'react';
import './Box.scss';

export interface IFootBoxProps {
  getLangString: (value: string) => string;
}

export const FootBox = (props: IFootBoxProps): JSX.Element => {
  return (
    <React.Fragment>
      <Box className="box-container">
        <div className="box-item">
          <div className="box-title">{props.getLangString('footBox.title')} </div>
          <div className="box-text">{props.getLangString('footBox.body')}</div>
          <div className="box-text"> {props.getLangString('footBox.foot')}</div>
        </div>
      </Box>
    </React.Fragment>
  );
};
