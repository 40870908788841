import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './TopNavigation.scss';
import { Drawer, Hidden, List, ListItem } from '@mui/material';
import { IMarker } from '../map/Marker';
import markerJson from '../../db/markerJson.json';

export interface ITopNavigationProps {
  getLangString: (value: string) => string;
}

export const TopNavigation = (props: ITopNavigationProps): JSX.Element => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setShowDrawer(open);
  };
  const Markers: IMarker[] = markerJson;

  const getNavList = () => {
    return (
      <List className="drawer-list">
        {Markers.map(({ url, title }, id) => (
          <ListItem key={id} className="drawer-list-item  " onClick={() => toggleDrawer(false)}>
            <a href={url} className="drawer-link" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />
              {title}
            </a>
          </ListItem>
        ))}
      </List>
    );
  };

  const getLogoContainer = (cssClass: string, logo: string) => {
    console.log(process.env.PUBLIC_URL + logo);
    return (
      <div className={cssClass}>
        <div id="nav-logo">
          <img src={process.env.PUBLIC_URL + logo} alt="Logo" />
        </div>
        {props.getLangString('topNavigation.live')}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Hidden mdUp>
        <div className="nav">
          <div onClick={() => toggleDrawer(true)} className="drawer-toggle-div">
            <FontAwesomeIcon icon={faBars} className="drawer-toggle-icon" />
          </div>
          <Drawer
            anchor="left"
            open={showDrawer}
            onClick={() => setShowDrawer(false)}
            PaperProps={{
              sx: {
                width: '250px',
              },
            }}
          >
            {getLogoContainer('nav-logo-container', '/favlogo.svg')}
            <div className="drawer-menu">{getNavList()}</div>
          </Drawer>
        </div>

        <div className="logo-container">{props.getLangString('topNavigation.live')}</div>
      </Hidden>

      <Hidden mdDown>
        <div className="nav">
          {getLogoContainer('nav-item', '/logo.svg')}

          <div className="nav-item nav-item-sm">{props.getLangString('topNavigation.title')}</div>
          <div className="nav-item">
            <div onClick={() => toggleDrawer(!showDrawer)} className="drawer-toggle-div">
              <FontAwesomeIcon icon={faBars} className="drawer-toggle-icon " />
            </div>
          </div>
          <Drawer
            anchor="right"
            open={showDrawer}
            onClick={() => setShowDrawer(!showDrawer)}
            PaperProps={{
              sx: {
                height: '136px',
                minWidth: '300px',
                margin: '100px 0px 0px 0px',
                borderRadius: '0px 0px 0px 12px',
                overflow: 'hidden',
              },
            }}
          >
            <div className="drawer-menu">
              <div className="drawer-menu-header">
                <div className="drawer-menu-header-title">{props.getLangString('menu.title')}</div>
              </div>
              {getNavList()}
            </div>
          </Drawer>
        </div>
      </Hidden>
    </React.Fragment>
  );
};
