import React, { Suspense } from 'react';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import routes from './components/navigation/routes';
import { TopNavigation } from './components/navigation/TopNavigation';
import { FootBox } from './components/box/FootBox';

function App(): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <div>
      <div>
        <Suspense fallback={<div>{t('global.loading')}</div>}>
          <TopNavigation getLangString={t} />
          <Routes>
            {routes.map(({ path, Component }, key) => (
              <Route
                path={path}
                key={key}
                element={
                  <>
                    <React.Fragment>
                      <Component getLangString={t} />
                    </React.Fragment>
                  </>
                }
              />
            ))}
          </Routes>
          <FootBox getLangString={t} />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
