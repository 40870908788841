import React from 'react';
import { GoogleMaps } from '../components/map/GoogleMaps';
export interface IHomeProps {
  getLangString: (value: string) => string;
}

export const Home = (props: IHomeProps): JSX.Element => {
  return (
    <React.Fragment>
      <GoogleMaps getLangString={props.getLangString} />
    </React.Fragment>
  );
};
