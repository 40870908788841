import React from 'react';
import CustomOption from './CustomStyles';
import GoogleMap from 'google-map-react';
import { GOOGLE_MAP_KEY } from '../../Constants';
import { IMarker } from './Marker';
import './GoogleMaps.scss';
import markerJson from '../../db/markerJson.json';
import { Hidden } from '@mui/material';

export interface IGoogleMapsProps {
  getLangString: (value: string) => string;
}

export const GoogleMaps = (props: IGoogleMapsProps): JSX.Element => {
  const center = { lat: 56.19864, lng: 14.902004 };

  const handleApiLoaded = (map: any, maps: any) => {
    const markers: IMarker[] = markerJson;

    markers.map((marker: IMarker, index: number) => {
      const newMarker = new maps.Marker({
        position: { lat: marker.lat, lng: marker.lng },
        map: map,
        icon: {
          url: marker.imageUrl,
        },
        index: index,
      });

      newMarker.addListener('click', () => {
        window.open(marker.url, '_blank');
      });

      return newMarker;
    });
  };

  const renderGoogleMap = (level: number) => {
    return (
      <GoogleMap
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        defaultCenter={center}
        defaultZoom={level}
        options={CustomOption}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMap>
    );
  };

  return (
    <div id="map-container" role="main">
      <Hidden xsUp>{renderGoogleMap(8)}</Hidden>
      <Hidden mdUp xsDown>
        {renderGoogleMap(9)}
      </Hidden>
      <Hidden lgUp mdDown>
        {renderGoogleMap(10)}
      </Hidden>
      <Hidden lgDown>{renderGoogleMap(11)}</Hidden>
    </div>
  );
};
